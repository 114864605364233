import {
    ReactIntegration,
    createReactRouterV6DataOptions,
    getWebInstrumentations,
    initializeFaro,
} from '@grafana/faro-react'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { matchRoutes } from 'react-router-dom'
import { getLocaleFromLocalStorage } from './app/commons/toolsbox/getLocaleFromLocalStorage'
import { obfuscateEvent } from './app/commons/toolsbox/logger/logger'
import { getRuntimeEnv } from './utils/getRuntimeEnv'
import {
    RuntimeEnv,
    isValidRuntimeEnvKeys,
} from './utils/getRuntimeEnv/runtimeEnvSchema'
import { Lang, defaultLocale } from 'src/tools/Helpers/Constants/Locale'

const initialLang = getLocaleFromLocalStorage()?.lang || defaultLocale.lang

const initMSW = async () => {
    if (import.meta.env.VITE_ENV === 'mock') {
        return import('./mocks/browser').then((module) => {
            module.worker.start({
                onUnhandledRequest: 'warn',
            })
            // eslint-disable-next-line no-console
            console.log(
                'MSW handlers',
                module.worker
                    .listHandlers()
                    .map((handler) => handler.info.header)
            )
        })
    } else {
        return Promise.resolve()
    }
}

const versionKeys = {
    VITE_VERSION: 'APP_VERSION',
    VITE_PRODUCT_VERSION: 'APP_VERSIONPRODUCT',
    VITE_YMD: 'APP_YMD',
}
const parseVersionKey = (key: string) => {
    return versionKeys[key as keyof typeof versionKeys] ?? key
}

const defineDefaultConfig = async () => {
    window.appRuntimeEnv = {} as RuntimeEnv
    try {
        const envConfig = import.meta.env
        Object.entries(envConfig).forEach(([key, value]) => {
            const parsedKey = parseVersionKey(key)
            if (isValidRuntimeEnvKeys({ [parsedKey]: value })) {
                window.appRuntimeEnv[parsedKey as keyof RuntimeEnv] = value
            }
        })
    } catch (error) {
        //
    }
}

const loadConfig = async () => {
    try {
        const config = await fetch('/config.json')
        const configJson = await config.json()
        window.appRuntimeEnv = { ...window.appRuntimeEnv, ...configJson }
    } catch (error) {
        //
    }
}

const loadAppVersionsConfig = async () => {
    try {
        const appVersions = await fetch('/app_versions.json')
        const appVersionsJson = await appVersions.json()
        window.appRuntimeEnv = {
            ...window.appRuntimeEnv,
            ...appVersionsJson,
        }
    } catch (error) {
        //
    }
}

const retrieveApiConfig = async () => {
    const apiUrl = getRuntimeEnv('VITE_OMAPI_URL')
    if (typeof apiUrl !== 'string') {
        return
    }
    try {
        const apiConfig = await fetch(apiUrl)
        const apiConfigJson = await apiConfig.json()
        const apiConfigObj = { APP_API_VERSION: apiConfigJson.version }
        window.appRuntimeEnv = {
            ...window.appRuntimeEnv,
            ...apiConfigObj,
        }
    } catch (error) {
        //
    }
}

const InitializeLanguageBeforeConstantsLoading = async (lang: Lang) => {
    await i18n.use(initReactI18next).init({
        lng: lang, // if you're using a language detector, do not define the lng option
        fallbackLng: defaultLocale.lang,
        debug: false,
        nsSeparator: false,
        keySeparator: '.',
        returnEmptyString: false,
    })
}

defineDefaultConfig()
    .then(() => loadConfig())
    .then(() => loadAppVersionsConfig())
    .then(() => retrieveApiConfig())
    .then(() => initMSW())
    .then(() => InitializeLanguageBeforeConstantsLoading(initialLang))
    .then(() => {
        /*
            RTK tools init must not be done before config load
                Because it's config load that define env variable from .env or .config.json
                if .env is missing, RTK will be init with undefined api url
        */
        import('./app/commons/api/rtk-utils').then(() =>
            import('./app').then((module) => module.default())
        )
    })

const GRAFANA_CLOUD_URL =
    'https://faro-collector-prod-eu-north-0.grafana.net/collect/1ba97eb70a5ed734547270a93771d7b5'
const GRAFANA_CLOUD_APP_NAME = 'Practitioners UI'
const GRAFANA_ENV_MAPPING: Record<string, string> = {
    dev: 'dev',
    release: 'staging',
    production: 'production',
}

initializeFaro({
    // we cannot send dev log to grafana because of CORS configured in the grafana app
    // if this is needed, create your own Grafana Cloud instance and change GRAFANA_CLOUD_URL
    paused: import.meta.env.DEV === true,
    url: GRAFANA_CLOUD_URL,
    app: {
        name: GRAFANA_CLOUD_APP_NAME,
        version: import.meta.env.VITE_VERSION,
        environment: GRAFANA_ENV_MAPPING[import.meta.env.VITE_ENV] ?? 'dev',
    },
    beforeSend: obfuscateEvent,
    sessionTracking: {
        session: {
            attributes: {
                user_id: 'not_logged',
            },
        },
    },
    instrumentations: [
        // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
        ...getWebInstrumentations(),

        // Initialization of the tracing package.
        // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
        new TracingInstrumentation(),
        new ReactIntegration({
            router: createReactRouterV6DataOptions({
                matchRoutes,
            }),
        }),
    ],
})
