import { z } from 'zod'

export const runtimeEnvSchema = z.object({
    VITE_API_URL: z.string().min(1).url().startsWith('https://').optional(),
    VITE_OMAPI_URL: z.string().min(1).url().startsWith('https://'),
    VITE_PRACTITIONERS_API_URL: z.string().min(1).url().startsWith('https://'),
    VITE_WEB_URL: z.string().min(1).url().startsWith('https://'),
    VITE_WEBAPP_URL: z.string().min(1).url().startsWith('https://'),
    APP_VERSION: z.string().min(1),
    APP_VERSIONPRODUCT: z.string().min(1),
    APP_YMD: z.string().or(z.number()),
    APP_API_VERSION: z.string().optional(),
})

export type RuntimeEnv = z.infer<typeof runtimeEnvSchema>

const partialRuntimeEnvSchema = runtimeEnvSchema.partial()
export const isValidRuntimeEnvKeys = (
    obj: object
): obj is Partial<RuntimeEnv> => {
    return (
        zodKeys(partialRuntimeEnvSchema).includes(Object.keys(obj)[0]) &&
        partialRuntimeEnvSchema.safeParse(obj).success
    )
}

/*
    If you find smarter way of doing that, feel free to replace
    (test object key existence in a Zod schema)
*/
const zodKeys = <T extends z.ZodTypeAny>(schema: T): Array<string> => {
    if (schema === null || schema === undefined) return []
    if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional)
        return zodKeys(schema.unwrap())
    if (schema instanceof z.ZodArray) return zodKeys(schema.element)
    if (schema instanceof z.ZodObject) {
        const entries = Object.entries(schema.shape)
        return entries.flatMap(([key, value]) => {
            const nested =
                value instanceof z.ZodType
                    ? zodKeys(value).map((subKey) => `${key}.${subKey}`)
                    : []
            return nested.length ? nested : key
        })
    }
    return []
}
